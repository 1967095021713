<template>
  <div>
    <!-- :dialogStyle="{ top: '10px' }" -->
    <a-modal v-model="visible" title="Detail Location" :footer="null" :width="700">
      <!-- <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Batal
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Kirim
        </a-button>
      </template> -->
      <GmapMap
        :key="componentKey"
        :center="{ lat: latitude, lng: longitude }"
        style="width: 100%; height: 400px;"
        :zoom="17"
        map-type-id="terrain"
      >
        <GmapMarker
          ref="myMarker"
          :clickable="false"
          :draggable="false"
          :position="google && new google.maps.LatLng(latitude, longitude)"
        />
      </GmapMap>
    </a-modal>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

export default {
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      visible: false,
      latitude: 0,
      longitude: 0,
      componentKey: 0,
    }
  },
  methods: {
    showModal(latitude, longitude) {
      this.visible = true
      this.latitude = parseFloat(latitude)
      this.longitude = parseFloat(longitude)
      this.componentKey += 1
    },
  },
}
</script>

<style></style>
