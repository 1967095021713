<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-12">
            <strong class="mr-2">Data Nasabah</strong>
            <!-- <a-button
              class="btn btn-outline-primary ml-2"
              @click="sendToForm('Create')"
              >Tambah Data</a-button
            > -->
          <a-select class="mr-2" v-model="selectedkecamatan" placeholder="Kecamatan" style="width: 200px;" @change="kecamatanchange">
            <a-select-option v-for="(data, index) in datakecamatan" :key="index" :value="data.kecamatan">{{ data.kecamatan }}</a-select-option>
          </a-select>
          <a-select class="mr-2" v-model="selectedkelurahan" placeholder="Kelurahan" style="width: 200px;" @change="getData">
            <a-select-option v-for="(data, index) in datakelurahan" :key="index" :value="data">{{ data }}</a-select-option>
          </a-select>
          <strong>Kualitas: </strong>
          <a-select class="mr-2" v-model="selectedkualitas" placeholder="Kualitas" style="width: 70px;" @change="filterkualitas">
            <a-select-option v-for="(data, index) in datakualitas" :key="index" :value="data">{{ data }}</a-select-option>
          </a-select>
          <a-button class="btn btn-outline-danger" v-if="selectedkecamatan !== null || selectedkelurahan !== null || selectedkualitas !== null" @click="resetFilter">Reset</a-button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <a-table
          style="
            margin-left: -26px;
            margin-right: -26px;
            margin-top: -21px;
          "
          :columns="columns"
          :dataSource="data"
          size="small"
          :pagination="{
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <span>
                <label v-if="column.dataIndex === 'norekening' || column.dataIndex === 'nama'" class="text-primary" style="cursor: pointer;" @click="detailModalFunction(record)">{{ text }}</label>
                <label v-else>{{ text }}</label>
              </span>
            </template>
          </template>
          <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <strong>alamat: </strong>{{ record.alamat }}
            <br>
            <strong>url: </strong>{{ record.url }}
          </p>
          <template slot="appList" slot-scope="text, record">
            <a-tag v-for="(data, index) in record.appLists" :key="index">{{ data.appName }}</a-tag>
          </template>
          <template slot="id_Default_App" slot-scope="text, record">
            <a-tag color="blue">{{ findName(record) }}</a-tag>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal title="Detail Kunjungan" v-model="detailModal.visible" :width="900" :dialogStyle="{ top: '30px' }">
      <template slot="footer">
        <a-button class="btn btn-danger" @click="detailModal.visible = false">
          Close
        </a-button>
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <vue-custom-scrollbar style="max-height: 500px; padding: 0 1.4rem;">
            <a-list
              :locale="{
                emptyText: 'Tidak pernah dikunjungi'
              }"
              item-layout="vertical"
              size="large"
              :data-source="dataall"
            >
              <!-- <div slot="footer"><b>Ini </b> footer</div> -->
              <a-list-item
                slot="renderItem"
                key="item.title"
                slot-scope="item"
              >
                <!-- <template slot="actions">
            </template> -->
                <div class="row">
                  <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                    <a-list-item-meta>
                      <template slot="description">
                        Kunjungan AO {{ item.nama_ao }} kepada nasabah
                        {{ item.nama_nasabah }}
                      </template>
                      <a slot="title" :href="item.href">{{ item.nama_ao }} - {{ convRelative(item.time) }}</a>
                      <a-avatar slot="avatar" :src="item.img_ao" />
                    </a-list-item-meta>
                    {{ item.keterangan }}
                    <div class="mt-3">
                      <span>
                        <a-icon type="like-o" style="margin-right: 8px;" />
                        {{ item.like }}
                      </span>
                      <a-divider class="mr-2 ml-2" type="vertical" />
                      <span>
                        <a-icon type="audit" style="margin-right: 8px;" />
                        Kunjungan ke {{ item.kunjungan_ke }}
                      </span>
                      <a-divider class="mr-2 ml-2" type="vertical" />
                      <span @click="openmodalreplies(item.comment)" style="cursor: pointer;">
                        <a-icon type="message" style="margin-right: 8px;" />
                        {{ item.comment.length }}
                      </span>
                    </div>
                    <strong class="mt-2" @click="openmodalreplies(item.comment)" style="cursor: pointer;"
                      >Comments</strong
                    >
                  </div>
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                    <img width="272" alt="logo" :src="item.img_kunjungan" @click="zoomGambar(item, 'img_kunjungan', 'nama_ao')" style="cursor: pointer;"/>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="row">
                    <div
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      v-for="(replies, index) in item.comment"
                      :key="index"
                    >
                      <div v-if="index <= 2">
                        <strong class="mr-2">{{ replies.nama_ao || "" }}</strong>
                        <label style="margin: 0 !important">{{ replies.comment }}</label>
                      </div>
                      <div v-if="index <= 2 && item.comment.length - 3 > 0">
                        <strong class="mr-2" @click="openmodalreplies(item.comment)" style="cursor: pointer;"
                          >See
                          {{
                            item.comment.length === 0
                              ? 0
                              : item.comment.length - 3
                          }}
                          more comments</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </a-list-item>
            </a-list>
          </vue-custom-scrollbar>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="modalreplies.visible" title="Comments" :dialogStyle="{ top: modalreplies.commentdata.length >= 3 ? '30px' : '' }">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="modalreplies.visible = false;">
          Cancel
        </a-button>
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-list
            :locale="{
              emptyText: 'No one has commented yet'
            }"
            class="comment-list"
            item-layout="horizontal"
            :data-source="modalreplies.commentdata"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-comment :author="item.nama_ao || ''" :avatar="item.img_url">
                <template slot="actions">
                  <span>
                    <a-icon type="like-o" style="margin-right: 8px;" />
                    {{ item.like || 0 }}
                  </span>
                  <span>
                    <a-icon type="like-o" style="margin-right: 8px;" />
                    {{ item.dislike || 0 }}
                  </span>
                  <span>
                    <a-icon type="message" style="margin-right: 8px;" />
                    {{ item.comment.length }}
                  </span>
                  <span>
                    {{ convRelative(item.time) }}
                  </span>
                </template>
                <p slot="content">
                  {{ item.comment }}
                </p>
                <!-- <a-tooltip slot="datetime" :title="item.datetime.format('YYYY-MM-DD HH:mm:ss')">
                          <span>{{ item.datetime.fromNow() }}</span>
                        </a-tooltip> -->
              </a-comment>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-modal>
    <zoomgambar ref="czoomgambar" />
    <mapdetail ref="detailmap"></mapdetail>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import moment from 'moment'
import zoomgambar from '@/components/lou/zoomgambar'
import mapdetail from '@/components/lou/map'
// import formModal from '../form'

const data = []

export default {
  components: {
    vueCustomScrollbar,
    zoomgambar,
    mapdetail,
    // formModal,
  },
  watch: {
    'detailModal.visible'(newValue, oldValue) {
      if (newValue) {
        this.detailModal.datatable = []
        console.log('this.detailModal.datatable', this.detailModal.datatable)
      } else {
        this.detailModal.datatable = null
        console.log('this.detailModal.datatable', this.detailModal.datatable)
      }
    },
  },
  data() {
    return {
      modalreplies: {
        visible: false,
        commentdata: [],
      },
      dataall: [],
      detailModal: {
        visible: false,
        columns: [
          {
            title: 'Rekening',
            dataIndex: 'rekening',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.rekening
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Nama',
            dataIndex: 'nama',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.nama.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Alamat',
            dataIndex: 'alamat',
            ellipsis: true,
            scopedSlots: {
              customRender: 'alamat',
            },
          },
          {
            title: 'Kunjungan',
            dataIndex: 'jumlah_kunjungan',
            scopedSlots: {
              customRender: 'jumlah_kunjungan',
            },
          },
        ],
        childcolumns: [
          {
            title: 'Foto',
            dataIndex: 'foto',
            scopedSlots: {
              customRender: 'foto',
            },
          },
          {
            title: 'Time',
            dataIndex: 'time',
            scopedSlots: {
              customRender: 'time',
            },
          },
          {
            title: 'User',
            dataIndex: 'user',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.user.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Keterangan',
            dataIndex: 'keterangan',
            scopedSlots: {
              customRender: 'keterangan',
            },
          },
        ],
        datatable: [],
      },
      selectedkecamatan: null,
      selectedkualitas: null,
      selectedkelurahan: null,
      datakecamatan: [],
      datakualitas: [],
      datakelurahan: [],
      name: 'TablesAntdBasic',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      popinput: {
        password: '',
        re_password: '',
      },
      columns: [
        // {
        //   title: 'Action',
        //   key: 'action',
        //   width: 120,
        //   scopedSlots: { customRender: 'action' },
        // },
        // {
        //   title: 'id',
        //   width: 75,
        //   dataIndex: 'id',
        //   scopedSlots: {
        //     filterDropdown: 'filterDropdown',
        //     filterIcon: 'filterIcon',
        //     customRender: 'customRender',
        //   },
        //   onFilter: (value, record) =>
        //     record.id.toString().toLowerCase().includes(value.toLowerCase()),
        //   onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //       setTimeout(() => {
        //         this.searchInput.focus()
        //       }, 0)
        //     }
        //   },
        // },
        {
          title: 'norekening',
          dataIndex: 'norekening',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.norekening.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'nama',
          dataIndex: 'nama',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'kecamatan',
          dataIndex: 'kecamatan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kecamatan.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'kelurahan',
          dataIndex: 'kelurahan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kelurahan.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'longitude',
          dataIndex: 'longitude',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.longitude.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'latitude',
          dataIndex: 'latitude',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.latitude.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'alamat',
          dataIndex: 'alamat',
          ellipsis: true,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.alamat.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'url',
          dataIndex: 'url',
          ellipsis: true,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.url.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        // {
        //   title: 'Allow App',
        //   key: 'appLists',
        //   width: 200,
        //   scopedSlots: { customRender: 'appList' },
        // },
      ],
      selectedidbpr: '',
      databpr: [],
      table: 'users',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      data,
      componentKey: 0,
    }
  },
  mounted: function () {
    this.getDataKecamatan()
  },
  methods: {
    async detailModalFunction(record) {
      var res = await lou.customUrlGet('loc/kunjungan?nasabah_id=' + record.id, false, true)
      if (res) {
        this.dataall = res.data
        this.detailModal.visible = true
      }
    },
    openmodalreplies(comment) {
      this.modalreplies.visible = true
      this.modalreplies.commentdata = comment
    },
    filterkualitas() {
      var newData = this.backupData.filter(x => x.kualitas === this.selectedkualitas)
      this.data = this.$g.clone(newData)
    },
    resetFilter() {
      this.selectedkecamatan = null
      this.selectedkualitas = null
      this.selectedkelurahan = null
      this.getData()
    },
    kecamatanchange() {
      this.selectedkelurahan = null
      this.datakelurahan = []
      var targetIndex = this.datakecamatan.findIndex(x => x.kecamatan === this.selectedkecamatan)
      if (targetIndex >= 0) {
        this.datakelurahan = this.datakecamatan[targetIndex].kelurahan
        this.getData()
      }
    },
    async getDataKecamatan() {
      var res = await lou.customUrlGet('loc/list/kecamatan', false, true)
      if (res) {
        this.datakecamatan = res.data
      }
      this.getData()
    },
    findName(record) {
      if (record.appLists.length === 0) {
        return 'Tidak ada List app'
      } else {
        var tindex = record.appLists.findIndex(x => x.id === record.id_Default_App)
        // console.log('tindex', tindex)
        return record.appLists[tindex].appName
      }
    },
    hide(index) {
      this.data[index].changePassPop = false
      this.popinput = {
        password: '',
        re_password: '',
      }
    },
    async changePassword(index) {
      if (this.popinput.password === this.popinput.re_password) {
        var fd = {
          id: this.data[index].id,
          password: this.popinput.password,
        }
        var res = await lou.customUrlPut('manage/users/changepassword', fd)
        if (res) {
          this.data[index].changePassPop = false
          lou.shownotif('Success', res.message, 'success')
          this.popinput = {
            password: '',
            re_password: '',
          }
        }
      } else {
        lou.shownotif('Not Valid!', 'Password tidak sama!')
      }
    },
    sendToForm(action, data = {}) {
      this.$refs.formModal.showModal(action, data, this.selectedidbpr)
    },
    async getMaster() {
      var res = await lou.readMaster('manage/users/bprlist', false, true)
      if (res) {
        this.databpr = res.data
        if (this.databpr !== null && this.databpr.length !== 0) {
          this.selectedidbpr = this.databpr[0].id_bpr
          this.getData()
        }
      }
    },
    async getData() {
      this.componentKey += 1
      var imbuhan = this.selectedkecamatan !== null ? '?kecamatan=' + this.selectedkecamatan : ''
      imbuhan += this.selectedkelurahan !== null ? '&kelurahan=' + this.selectedkelurahan : ''
      var res = await lou.customUrlGet('loc' + imbuhan, false, true)
      // var ndata = []
      // console.log('res.data', res.data)
      if (res) {
        this.data = res.data
        this.backupData = this.$g.clone(this.data)
        if (this.data.length !== 0) {
          var groupbykualitas = this.backupData.reduce(function (r, a) {
            if (a.kualitas !== undefined && a.kualitas !== null) {
              r[a.kualitas] = r[a.kualitas] || []
              r[a.kualitas].push(a)
            }
            return r
          }, Object.create(null))
          var kualitas = Object.keys(groupbykualitas)
          this.datakualitas = kualitas
        }
      }
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete('manage/users/' + deldata.id + '?id_bpr=' + this.selectedidbpr)
          this.data = response.data
          this.getData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    zoomGambar(record, column, caption) {
      var data = {
        imageUrl: record[column],
        caption: record[caption],
      }
      this.$refs.czoomgambar.lihatgambar(data)
    },
    linkLokasi(record, column) {
      var latitude = record.latitude
      var longitude = record.longitude
      this.$refs.detailmap.showModal(latitude, longitude)
      // var link =
      //   'https://www.google.com/maps/search/?api=1&query=' +
      //   latitude +
      //   ',' +
      //   longitude
      // return link
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    convRelative(date) {
      var ndate = date.replaceAll('T', ' ')
      return moment(ndate).startOf('hour').fromNow()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>
